exports.components = {
  "component---src-pages-biography-jsx": () => import("./../../../src/pages/biography.jsx" /* webpackChunkName: "component---src-pages-biography-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */),
  "component---src-pages-works-jsx": () => import("./../../../src/pages/works.jsx" /* webpackChunkName: "component---src-pages-works-jsx" */),
  "component---src-templates-collection-details-jsx": () => import("./../../../src/templates/CollectionDetails.jsx" /* webpackChunkName: "component---src-templates-collection-details-jsx" */),
  "component---src-templates-work-details-jsx": () => import("./../../../src/templates/WorkDetails.jsx" /* webpackChunkName: "component---src-templates-work-details-jsx" */)
}

